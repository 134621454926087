import { Controller } from "stimulus"
import { arpu_currency_formatting, arpu_number_from_currency_formatting } from "../packs/arpu_utils.js"
import { throttle, debounce } from "lodash"
import Rails from "@rails/ujs"

export default class extends Controller {

  static targets = ["originalPrice", "price", "comparedAtPrice", "originalComparedAtPrice", "featuredImage", "featuredImageSrc", "featureGidUri", "variantTitle", "discount", "variantSelection", "percentageDiscount", "amountDiscount", "discountStrategy"]

  static values = {
    moneyFormattingJsNumberFormat: String,
    moneyFormattingPrefix: String,
    moneyFormattingSuffix: String,
    currency: String,
    searchDiscountCodeUrl: String,
    discount: Number,
    strategies: Object,
    discountCode: String
  }

  static classes = ['invisible']

  connect() {
    this.throttleChangeDiscount = throttle(this.changeDiscount)
    this.bounceChangeDiscountCode = debounce(this.changeDiscountCode.bind(this), 800)   

    this.showDiscountField(this.discountStrategyTarget.value)
    if (this.discountValue) this.discount['target']['value'] = this.normalizeDiscount(this.discountValue)
    this.discountTarget.value = this.setDiscount(this.discountValue)
  }

  selectDiscountOption(event) {
    const option = event.currentTarget.value
    const discount = this.discount['value']
    this.discountTarget.value = this.setDiscount(discount)
    this.showDiscountField(option)
    this.applyDiscount(discount)
  }

  changeDiscount(event) {
    const discount = this.discount['value']
    this.discountTarget.value = this.setDiscount(discount)
    this.applyDiscount(discount)
  }

  changeDiscountCode(event) {
    this.clearErrorMessages()
    if(!event.target.value) {
      this.setDisabledDiscountFields(false)
      return
    }
    const self = this
    const changeDiscountCodeCallback = (response) => {
      const discount = response['discount']['value']
      const strategy = response['discount']['strategy']
      
      self.discountStrategyTarget.value = strategy
      self.selectDiscountOption({currentTarget: {value: strategy}})
      if (strategy == 'amount') {
        self.amountDiscountTarget.lastElementChild.value = discount
        self.changeDiscount({currentTarget: {value: discount}})
      } else if(strategy == 'percentage') {
        self.percentageDiscountTarget.value = parseInt(discount)
      }
      self.setDisabledDiscountFields(true)
    }

    const data = `discount_code=${encodeURIComponent(event.target.value)}&product_gid_uri=${encodeURIComponent(this.featureGidUriTarget.value)}`
    Rails.ajax({
      url: this.searchDiscountCodeUrlValue,
      type: 'GET',
      data: data,
      contentType: 'application/json',
      success: changeDiscountCodeCallback,
      error: (response) => {
        response.errors.forEach((error) => {
          event.target.insertAdjacentHTML('afterEnd', this.errorMessage(error))
        })
      }
    })
  }

  setDisabledDiscountFields(value) {
    const action = value ? 'add' : 'remove'
    this.amountDiscountTarget.querySelector('input').classList[action]('disabled');
    this.percentageDiscountTarget.classList[action]('disabled');
    this.discountStrategyTarget.classList[action]('disabled');
  }

  showDiscountField(strategy) {
    switch (strategy) {
      case 'percentage':
        this.amountDiscountTarget.classList.add(this.invisibleClass)
        this.percentageDiscountTarget.classList.remove(this.invisibleClass)
        break
      case 'amount':
        this.percentageDiscountTarget.classList.add(this.invisibleClass)
        this.amountDiscountTarget.classList.remove(this.invisibleClass)
        break
      default:
        this.percentageDiscountTarget.classList.add(this.invisibleClass)
        this.amountDiscountTarget.classList.add(this.invisibleClass)
    }
  }

  applyDiscount(discount) {
    if (!this.hasOriginalPriceTarget) return

    let original_price = this.originalPriceTarget.value
    let original_compared_at_price = this.originalComparedAtPriceTarget.value

    if (discount == 0) {
      this.priceTarget.innerHTML = this.offPriceTag(original_price, 0)

      this.toggleComparedAtPrice(original_price, original_compared_at_price)

      if (this.originalComparedAtPriceTarget != undefined) {
        this.comparedAtPriceTarget.innerHTML = this.offPriceTag(original_compared_at_price, 0)
      }
      if (original_compared_at_price == 0 && original_price < original_compared_at_price) {
        this.priceTarget.classList.add('sale')
      }
    }
    else {
      this.priceTarget.classList.add('sale')
      this.priceTarget.innerHTML = this.offPriceTag(original_price, discount)
      this.comparedAtPriceTarget.innerHTML = this.offPriceTag(original_price, 0)
      this.comparedAtPriceTarget.classList.remove('filter--notMatch')
    }

  }

  offPriceTag(price, discount) {
    let new_price = this.offPrice(price, discount)
    if (new_price == 0) return 'Free'
    else return this.currency(new_price)
  }

  offPrice(price, discount) {
    const _price = Number(price)
    const _discount = Number(discount)
    if (this.discountStrategyTarget.value == 'amount') {
      return (_price - _discount).toFixed(2)
    }
    return (_price * (1 - discount / 100.0)).toFixed(2)
  }

  applyVariantOption(event) {
    const selection = event.currentTarget
    const option = selection.options[selection.selectedIndex]
    let variant = JSON.parse(option.dataset.variant)
    this.changeFeaturedImage(variant.featured_image_src)
    this.changePrices(variant.price, variant.compared_at_price)
    this.changeVariantTitle(variant.variant_title)
  }

  changeFeaturedImage(image_src) {
    const feat_img = this.featuredImageTarget
    const feat_img_src = this.featuredImageSrcTarget
    feat_img.src = image_src
    feat_img_src.value = image_src
  }

  changePrices(price, compared_at_price) {
    const form_price = this.originalPriceTarget
    const form_compared_at_price = this.originalComparedAtPriceTarget
    const label_price = this.priceTarget
    const label_compared_at_price = this.comparedAtPriceTarget
    const _discount = this.discount['value']
    form_price.value = price
    form_compared_at_price.value = compared_at_price
    label_price.innerHTML = this.offPriceTag(price, _discount)
    if (_discount > 0) {
      label_compared_at_price.innerHTML = this.offPriceTag(price, 0)
    }
    else {
      label_compared_at_price.innerHTML = this.offPriceTag(compared_at_price, 0)
      this.toggleComparedAtPrice(price, compared_at_price)
    }
  }

  changeVariantTitle(title) {
    const variant_title = this.variantTitleTarget
    variant_title.value = title
  }

  toggleComparedAtPrice(price, compared_at_price) {
    if (price == compared_at_price) {
      this.comparedAtPriceTarget.classList.add('filter--notMatch')
    }
    else {
      this.comparedAtPriceTarget.classList.remove('filter--notMatch')
    }
  }

  currency(value) {
    let n_value = Number(value)
    let format = this.moneyFormattingJsNumberFormatValue
    let prefix = this.moneyFormattingPrefixValue
    let suffix = this.moneyFormattingSuffixValue
    let currency_code = this.currencyValue
    return (n_value == 0) ? "" : arpu_currency_formatting(value, prefix, format, suffix, currency_code)
  }

  setDiscount(discount) {
    if (this.discountStrategyTarget.value == 'amount') {
      return this.discountTarget.value = Math.round(discount * 100)
    }
    return discount
  }

  normalizeDiscount(discount) {
    const isAmount = this.discountStrategyTarget.value == 'amount'
    if (!this.hasOriginalPriceTarget) return discount

    if (Number(discount) > Number(this.originalPriceTarget.value) && isAmount) {
      const discountAmount = this.originalPriceTarget.value - 0.01
      return this.amountDiscountTarget.lastElementChild.value = discountAmount
    }
    return discount
  }

  errorMessage(errorMessage) {
    return `<p class="error-text" style="font-size: 0.75rem; margin: 0">${errorMessage}</p>`
  }

  clearErrorMessages() {
    const errors = document.querySelectorAll('.error-text')
    errors.forEach((error) => error.remove())
  }

  get discount() {
    return {
      no_discount: { value: 0, target: this.discountTarget },
      free: { value: 100, target: this.discountTarget },
      percentage: { value: this.percentageDiscountTarget.value, target: this.percentageDiscountTarget },
      amount: { value: this.normalizeDiscount(this.amountDiscountTarget.lastElementChild.value), target: this.amountDiscountTarget.lastElementChild }
    }[this.discountStrategyTarget.value]
  }
}
